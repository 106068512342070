import 'core-js/stable';
import 'regenerator-runtime/runtime';

import fetch from 'isomorphic-unfetch';

import { MediaCenterScreen, PlaylistSlideConfig, Slide } from './slideshow-player-model';
import { ensurePollingStarted, getQueryParamValue, registerPollCallback } from './slideshow-player-service';

// used only in this file
let slideShowUpdateIn: number = 0;
let playlistSlideConfigs: PlaylistSlideConfig[] = [];
let currentIndex: number = 0;

// used for building the html
let mediaCenterScreen: MediaCenterScreen | null = null;
let slideshowHeight: number = 0;
let slideshowWidth: number = 0;
let isOperatingTime: boolean = false;
let sanitizedInfobannerContent: string = '';
let infobannerClass: string = '';
let infobannerBackground: string = '';
let fullscreenInfobanner: boolean = false;

let transform: string = '';
let marginLeft: number = 0;
let infobannerHeight: string = '';
let infobannerWidth: string = '';

let currentSlideConfig: PlaylistSlideConfig | null = null;
let preloadSlideConfig: PlaylistSlideConfig | null = null;

let timeLeftSlide: number = 0;
let timeLeftUpdate: number = 0;
let screenDataReceivedAtleastOnce = false;
let noDataReceivedCounter: number = 0;

const preloadTimeMs = 1000;
const checkSlideIntervalMs = 1000;
const movieTypes = ['youtube', 'video'];

const queryParams = window.location.search.toLowerCase().split('&');

const nightModeParam = getQueryParamValue(queryParams, 'nightmode');
const nightModeDisabled = nightModeParam === 'off' || nightModeParam === 'false';

const showInfoParam = getQueryParamValue(queryParams, 'showinfo');
let showInfo = showInfoParam === 'true';
const infopanelDiv = document.querySelector<HTMLDivElement>('.sis-slide-info') as HTMLDivElement;
infopanelDiv.addEventListener('click', () => (showInfo = false));

const slidesContainerDiv = document.querySelector<HTMLDivElement>('#displayed-slides-container') as HTMLDivElement;

function onScreenUpdate(screen: MediaCenterScreen | null, nextUpdateAt: number): void {
  slideShowUpdateIn = nextUpdateAt;

  fullscreenInfobanner = screen?.infobanner?.position === 'full';

  if (screen) {
    mediaCenterScreen = screen;
    screenDataReceivedAtleastOnce = true;
    noDataReceivedCounter = 0;

    slideshowWidth = screen.slideshowWidth ? screen.slideshowWidth : window.screen.width * devicePixelRatio;
    slideshowHeight = screen.slideshowHeight ? screen.slideshowHeight : window.screen.height * devicePixelRatio;

    isOperatingTime = nightModeDisabled || isScreenOperatingTime(screen.startTime, screen.endTime);
    if (isOperatingTime && screen.infobanner) {
      sanitizedInfobannerContent = screen.infobanner.content;
      infobannerClass = `sis-infobanner-${screen.infobanner.position}`;
      infobannerBackground = screen.infobanner.background;
    }
    updatePlaylistSlides(screen.slides);
  } else {
    noDataReceivedCounter++;
  }

  updateBackground();
  updateNightModeHtml();
  updateErrorDiv();
  updateErrorToast();
  onWindowResize();
}

function isScreenOperatingTime(startTime: string, endTime: string): boolean {
  const now = new Date();
  let hourNow = now.getHours().toString();
  if (hourNow.length < 2) {
    hourNow = `0${hourNow}`;
  }
  let minuteNow = now.getMinutes().toString();
  if (minuteNow.length < 2) {
    minuteNow = `0${minuteNow}`;
  }

  const timeNow = `${hourNow}:${minuteNow}`;
  const timeStart = startTime.substring(0, 5);
  const timeEnd = endTime.substring(0, 5);

  const continuousOperating = timeStart === '00:00' && timeEnd === '00:00';
  return continuousOperating || (timeStart <= timeNow && timeEnd >= timeNow);
}

async function updatePlaylistSlides(newSlides: Slide[] | null | undefined): Promise<void> {
  if (!newSlides || fullscreenInfobanner) {
    playlistSlideConfigs = [];
    console.log('empty playlist or full screen infobanner');
    return;
  }

  // check single movie slide and double it
  if (newSlides.length == 1 && movieTypes.includes(newSlides[0].type)) {
    newSlides.push({ ...newSlides[0] });
    newSlides[0].order += 1;
  }

  // check single movie slide and 1s slide. preload of video is not working with only one other 1s slide.
  if (newSlides.length == 2) {
    if (movieTypes.includes(newSlides[0].type) && newSlides[1].duration == 1) {
      newSlides[1].duration = 2;
    } else if (movieTypes.includes(newSlides[1].type) && newSlides[0].duration == 1) {
      newSlides[0].duration = 2;
    }
  }

  const newPlaylistSlideConfigs: PlaylistSlideConfig[] = await Promise.all(newSlides.map((s) => createPlaylistSlideConfig(s)));
  if (!newPlaylistSlideConfigs) {
    return;
  }

  const newSlidesSerialized = JSON.stringify(newSlides);
  const currentSlidesSerialized = JSON.stringify(playlistSlideConfigs.map((config) => ({ ...config.slide })));

  if (newSlidesSerialized == currentSlidesSerialized) {
    return;
  }

  console.log(`update playlist, number of slides: ${newPlaylistSlideConfigs.length}`);

  playlistSlideConfigs = newPlaylistSlideConfigs.sort((a, b) => a.slide.order - b.slide.order);

  // remove all slides that are not the current slide and are no longer part of the displayed slides (was removed from playlist)
  document.querySelectorAll('.sis-slide').forEach((slideDiv) => {
    const id = slideDiv.getAttribute('id')!;

    const slideShouldBeRemoved = !playlistSlideConfigs.find((s) => id === s.id);
    if (slideShouldBeRemoved) {
      console.log(`remove not used slide: ${slideDiv.id}`);
      slideDiv.remove();
      return;
    }
  });
}

function onWindowResize(): void {
  const scale = Math.min(Math.min(window.innerHeight / slideshowHeight, 1), Math.min(window.innerWidth / slideshowWidth, 1));

  marginLeft = (window.innerWidth - slideshowWidth * scale) / 2;
  transform = `scale(${scale})`;

  if (mediaCenterScreen?.infobanner) {
    const height = Math.round(parseInt(mediaCenterScreen.infobanner.height, 10) * scale);
    infobannerHeight = mediaCenterScreen.infobanner.height.includes('%')
      ? (infobannerHeight = mediaCenterScreen.infobanner.height)
      : (infobannerHeight = `${height}px`);
    infobannerWidth = `${window.innerWidth / scale}px`;
  }

  const iframes = document.querySelectorAll('iframe');
  iframes.forEach((iframe) => updateIframeStyle(iframe));

  updateInfobannerHtml();
}

async function createPlaylistSlideConfig(slide: Slide): Promise<PlaylistSlideConfig> {
  const playlistSlideConfig: PlaylistSlideConfig = {
    id: `${slide.hash}-${slide.order}`,
    safeUrl: getSlideUrlWithHash(slide),
    preload: movieTypes.includes(slide.type),
    backgroundColor: '#000000',
    slide,
    shouldBeRemoved: false,
    shownUntil: null,
  };

  if (slide.url.includes('mediacenter.blob.core.windows.net')) {
    try {
      const separator = slide.url.includes('?') ? '&' : '?';
      const htmlRequest = await fetch(`${slide.url}${separator}h=${slide.hash}`);
      if (htmlRequest.ok) {
        const regexp = /<style[\S\s]*body[\S\s]*{[\S\s]*background: ?(#?\w*)[\S\s]*?<\/style>/gim;
        const backgroundColorMatches = regexp.exec(await htmlRequest.text());
        if (backgroundColorMatches) {
          playlistSlideConfig.backgroundColor = backgroundColorMatches[1];
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  return playlistSlideConfig;
}

function startSlideShow(): void {
  // check every second what slide should be displayed
  setInterval(() => {
    const now = Date.now();
    if (isOperatingTime) {
      // need to display next slide
      if (!currentSlideConfig || now > currentSlideConfig.shownUntil!) {
        const nextSlideConfigToDisplay = getNextSlideConfig();

        if (nextSlideConfigToDisplay) {
          const shownUntil =
            Date.now() +
            nextSlideConfigToDisplay.playlistSlideConfig.slide.duration * 1000 +
            (nextSlideConfigToDisplay.playlistSlideConfig.preload ? 1000 : 0);
          nextSlideConfigToDisplay.playlistSlideConfig.shownUntil = shownUntil - (nextSlideConfigToDisplay.playlistSlideConfig.preload ? 1000 : 0);

          currentSlideConfig = nextSlideConfigToDisplay.playlistSlideConfig;
          currentIndex = nextSlideConfigToDisplay.index;
        } else {
          currentSlideConfig = null;
        }
      }

      // preload next slide, if it is an autoplay video
      if (!currentSlideConfig || (now >= currentSlideConfig.shownUntil! - preloadTimeMs && now < currentSlideConfig.shownUntil!)) {
        const newPreloadSlide = getNextSlideConfig();
        if (newPreloadSlide && newPreloadSlide.playlistSlideConfig.preload && currentSlideConfig !== newPreloadSlide.playlistSlideConfig) {
          preloadSlideConfig = newPreloadSlide.playlistSlideConfig;
          console.log(`preload: ${preloadSlideConfig.slide.name} [${preloadSlideConfig?.slide.order}]`);
        } else {
          preloadSlideConfig = null;
        }
      }
    }

    updateHtmlForScreenUpdate();
    updateDisplayInfo();
  }, checkSlideIntervalMs);

  if (showInfo) {
    setInterval(() => {
      const now = Date.now();
      if (currentSlideConfig?.shownUntil) {
        timeLeftSlide = Math.max(0, Math.round((currentSlideConfig.shownUntil - now) / 1000));
      }
      timeLeftUpdate = Math.round((slideShowUpdateIn - now) / 1000);
    }, 100);
  }
}

function getNextSlideConfig(): {
  playlistSlideConfig: PlaylistSlideConfig;
  index: number;
} | null {
  const playlistLength = playlistSlideConfigs.length;
  if (playlistLength == 0) {
    return null;
  }

  const nextIndex = currentSlideConfig ? (currentIndex + 1) % playlistLength : 0;
  const nextSlideInOrder = playlistSlideConfigs[nextIndex];

  return { playlistSlideConfig: nextSlideInOrder, index: nextIndex };
}

function getSlideUrlWithHash(slide: Slide): string {
  const separator = slide.url.includes('?') ? '&' : '?';
  return `${slide.url}${separator}h=${slide.hash}`;
}

function updateHtmlForScreenUpdate(): void {
  const showSlides = isOperatingTime && playlistSlideConfigs.length && !fullscreenInfobanner;
  if (!showSlides) {
    while (slidesContainerDiv.firstChild) {
      slidesContainerDiv.removeChild(slidesContainerDiv.firstChild);
    }
    return;
  }

  const slideDivs = document.querySelectorAll<HTMLDivElement>('.sis-slide');
  const existingSlideDivIds: string[] = [];
  slideDivs.forEach((element) => existingSlideDivIds.push(element.getAttribute('id')!));

  playlistSlideConfigs.forEach((playlistSlideConfig) => {
    const isCurrentSlide = playlistSlideConfig.id === currentSlideConfig?.id;

    // slide Div
    let slideDiv: HTMLDivElement;
    const slideExistsAndIsInPlaylist = existingSlideDivIds.includes(playlistSlideConfig.id);
    const slideIsInPlaylist = playlistSlideConfigs.findIndex((s) => s.id === playlistSlideConfig.id) > -1;

    if (slideExistsAndIsInPlaylist) {
      // update existing slide div
      slideDiv = document.getElementById(playlistSlideConfig.id) as HTMLDivElement;
    } else if (slideIsInPlaylist) {
      // create slide div
      slideDiv = document.createElement('div');
      slideDiv.setAttribute('id', playlistSlideConfig.id);
      slideDiv.classList.add('sis-slide');

      slidesContainerDiv.appendChild(slideDiv);
    } else {
      // do nothing, since slide is not in playlist anymore
      return;
    }

    mediaCenterScreen?.fadeInEnabled ? slideDiv.classList.add('sis-slide-fadein') : slideDiv.classList.remove('sis-slide-fadein');

    setSlideVisiblityClass(slideDiv, isCurrentSlide);
    slideDiv.style.background = playlistSlideConfig.backgroundColor;

    // IFrame
    const showSlideIFrame = isCurrentSlide || !playlistSlideConfig.preload || preloadSlideConfig?.id === playlistSlideConfig.id;
    let iframeElement = slideDiv.querySelector<HTMLIFrameElement>('iframe');

    if (showSlideIFrame) {
      if (!iframeElement) {
        console.log(`create iframe: ${playlistSlideConfig.slide.name}`);

        iframeElement = document.createElement('iframe');
        iframeElement.setAttribute('id', 'sis-iframe');
        iframeElement.setAttribute('frameborder', '0');
        iframeElement.setAttribute('src', playlistSlideConfig.safeUrl);
        iframeElement.classList.add('sis-iframe');

        updateIframeStyle(iframeElement);

        slideDiv.appendChild(iframeElement);
      }
    } else {
      if (iframeElement) {
        console.log(`remove iframe: ${slideDiv.id}`);
        iframeElement.remove();
      }
    }
  });
}

function updateBackground(): void {
  const backgroundDiv = document.querySelector<HTMLDivElement>('.sis-background') as HTMLDivElement;
  if (playlistSlideConfigs?.length && mediaCenterScreen?.backgroundColor) {
    backgroundDiv.style.backgroundColor = mediaCenterScreen.backgroundColor;
  } else {
    backgroundDiv.style.backgroundColor = '#000000';
  }
}

function updateNightModeHtml(): void {
  const nightModeDiv = document.querySelector<HTMLDivElement>('.sis-night-mode') as HTMLDivElement;
  if (isOperatingTime || !screenDataReceivedAtleastOnce) {
    nightModeDiv.classList.add('sis-hidden');
  } else {
    nightModeDiv.classList.remove('sis-hidden');
    nightModeBounce();
  }
}

function updateDisplayInfo(): void {
  if (showInfo && currentSlideConfig && isOperatingTime) {
    infopanelDiv.classList.remove('sis-hidden');
    const locationInfo = document.querySelector<HTMLElement>('#sis-slide-info-location') as HTMLDivElement;
    locationInfo.innerText = mediaCenterScreen?.location ?? '';

    const currentSlideNameTd = document.querySelector<HTMLTableCellElement>('#sis-slide-info-currentslide-name') as HTMLTableCellElement;
    currentSlideNameTd.innerText = currentSlideConfig.slide.name;

    const nextSlideDiv = document.querySelector<HTMLDivElement>('.sis-slide-info-next-slide') as HTMLDivElement;
    nextSlideDiv.innerHTML = `next slide: ${timeLeftSlide}s`;

    const nextUpdateDiv = document.querySelector<HTMLDivElement>('.sis-slide-info-next-update') as HTMLDivElement;
    nextUpdateDiv.innerHTML = `next update: ${timeLeftUpdate}s`;
  } else {
    infopanelDiv.classList.add('sis-hidden');
  }
}

function updateInfobannerHtml(): void {
  const showInfobanner = mediaCenterScreen?.infobanner && isOperatingTime;
  const infobannerDiv = document.querySelector<HTMLDivElement>('.sis-infobanner') as HTMLDivElement;
  const infobannerInnerDiv = document.querySelector<HTMLDivElement>('.sis-infobanner-inner') as HTMLDivElement;
  if (showInfobanner) {
    infobannerDiv.className = `sis-infobanner ${infobannerClass}`;
    infobannerDiv.style.height = infobannerHeight;
    infobannerDiv.style.background = infobannerBackground;

    infobannerInnerDiv.innerHTML = sanitizedInfobannerContent;
    infobannerInnerDiv.style.transform = transform;
    infobannerInnerDiv.style.width = infobannerWidth;
  } else {
    infobannerDiv.classList.add('sis-hidden');
  }
}

function setSlideVisiblityClass(slideDiv: HTMLElement, isCurrentSlide: boolean): void {
  if (isCurrentSlide) {
    console.log(`show slide: ${slideDiv?.getAttribute('id')}`);
    slideDiv.classList.add('sis-visible-slide');
    slideDiv.classList.remove('sis-hidden-slide');
  } else {
    slideDiv.classList.remove('sis-visible-slide');
    slideDiv.classList.add('sis-hidden-slide');
  }
}

function updateIframeStyle(iframeElement: HTMLIFrameElement): void {
  iframeElement.style.height = `${slideshowHeight}px`;
  iframeElement.style.width = `${slideshowWidth}px`;
  iframeElement.style.transform = transform;
  iframeElement.style.marginLeft = `${marginLeft}px`;
}

function updateErrorDiv(): void {
  const errorDiv = document.querySelector<HTMLDivElement>('.sis-error-no-data') as HTMLDivElement;
  if (screenDataReceivedAtleastOnce) {
    errorDiv.classList.add('sis-hidden');
  } else {
    errorDiv.classList.remove('sis-hidden');
  }
}

function updateErrorToast(): void {
  const toastDiv = document.querySelector<HTMLDivElement>('.sis-error-toast') as HTMLDivElement;
  if (screenDataReceivedAtleastOnce && noDataReceivedCounter >= 5) {
    toastDiv.classList.remove('sis-hidden');
  } else {
    toastDiv.classList.add('sis-hidden');
  }
}

function nightModeBounce(): void {
  const img = document.querySelector<HTMLImageElement>('#nightmode') as HTMLImageElement;
  if (img) {
    img.style.bottom = (screen.availHeight - img.height) * Math.random() + 'px';
    img.style.left = (screen.availWidth - img.width) * Math.random() + 'px';
  }
}

setTimeout(() => {
  registerPollCallback(onScreenUpdate);
  ensurePollingStarted();
  // start slideshow on the full second
  setTimeout(startSlideShow, 1000 - new Date().getMilliseconds());
});

window.addEventListener('resize', onWindowResize, true);
