import 'core-js/stable';
import 'regenerator-runtime/runtime';

import fetch from 'isomorphic-unfetch';

import { Infobanner, MediaCenterScreen, Slide } from './slideshow-player-model';

let screenApiUrl: string | null = null;
let registeredCallbacks: Array<(data: MediaCenterScreen | null, nextUpdateAt: number) => void> = [];
let pollingStarted = false;
let currentScreen: MediaCenterScreen | null = null;
let nextUpdateAt: number;

const slideshowUpdateIntervalMs = Number(process.env.SLIDESHOW_UPDATE_INTERVAL) || 60000;

const urlParts = window.location.pathname
  .toLowerCase()
  .split('/')
  .filter((s) => !!s);
const urlParams = window.location.search.toLowerCase().split('&');

const tenantSisId = urlParts[1] ?? getQueryParamValue(urlParams, 'tenantsisid');
const contentType = urlParts[2] ?? getQueryParamValue(urlParams, 'contenttype');
const contentId = urlParts[3] ?? getQueryParamValue(urlParams, 'contentid');
const infobanner = getQueryParamValue(urlParams, 'infobanner');

const showInfobanner = infobanner !== 'hide';

if (tenantSisId != null && contentType != null && contentId != null) {
  const infobannerUrlPart = !infobanner || infobanner === 'hide' ? '' : `/${infobanner}`;

  screenApiUrl = `${process.env.BASE_URL_API}/api/slideshow/${tenantSisId}/${contentType}/${contentId}${infobannerUrlPart}`;
}

async function getScreen(): Promise<MediaCenterScreen | null> {
  if (screenApiUrl == null) {
    console.log('no screen api url');
    return null;
  }

  try {
    const response = await fetch(`${screenApiUrl}?${Math.random() * 1000}`);
    if (response.ok) {
      const json = await response.json();
      return adaptScreen(json);
    }
  } catch (error) {
    console.error(error);
  }

  return null;
}

async function pollData(): Promise<void> {
  const screen = await getScreen();
  currentScreen = screen;
  nextUpdateAt = Date.now() + slideshowUpdateIntervalMs;
  registeredCallbacks.forEach((callback) => callback(screen, nextUpdateAt));
}

function adaptScreen(item: any): MediaCenterScreen | null {
  if (!item) {
    return null;
  }

  const screen: MediaCenterScreen = {
    location: item.location,
    slideshowWidth: item.slideshowWidth,
    slideshowHeight: item.slideshowHeight,
    startTime: item.startTime,
    endTime: item.endTime,
    fadeInEnabled: item.fadeInEnabled ?? true,
    backgroundColor: item.backgroundColor ?? '#000000',
    slides: adaptSlides(item.slides),
    infobanner: showInfobanner ? adaptInfobanner(item.infoBanner) : null,
  };

  return screen;
}

function adaptSlides(item: any): Slide[] | null {
  if (!item) {
    return [];
  }

  const slides: Slide[] = item.map((e: any) => ({
    name: e.name,
    url: e.url,
    order: e.order,
    duration: e.duration ? e.duration : 10,
    startDate: e.startDate ? new Date(e.startDate) : undefined,
    endDate: e.endDate ? new Date(e.endDate) : undefined,
    hash: e.hash,
    type: e.fileType,
  }));

  return slides;
}

function adaptInfobanner(item: any): Infobanner | null {
  if (!item) {
    return null;
  }

  return {
    content: item.content,
    height: item.height,
    position: item.position,
    background: item.background,
  };
}

export function getQueryParamValue(queryParams: string[], valueName: string): string | null {
  if (queryParams[0]?.charAt(0) === '?') {
    queryParams[0] = queryParams[0].substring(1);
  }

  const searchPart = queryParams.find((p) => p.split('=')[0] === valueName);
  if (searchPart) {
    return searchPart.split('=')[1];
  }

  return null;
}

export function ensurePollingStarted(): void {
  if (!pollingStarted) {
    setInterval(pollData, slideshowUpdateIntervalMs);
    pollingStarted = true;
    if (currentScreen == null) {
      pollData();
    }
  }
}

export function registerPollCallback(callback: (screen: MediaCenterScreen | null, nextUpdateAt: number) => void): void {
  registeredCallbacks.push(callback);
  // Ensure late subscribers get the current data as well and don't have to wait
  // until the next refresh
  if (registeredCallbacks.length > 1) {
    callback(currentScreen, nextUpdateAt);
  }
}

// This only works for functions that have a name. Anonymous functions probably won't work
export function removePollingCallback(callback: (data: MediaCenterScreen | null) => void): void {
  // create filtered copy of the callback list so that a concurrent iteration over the list does not
  // produce unintented effects
  registeredCallbacks = registeredCallbacks.filter((f) => f.name !== callback.name);
}
